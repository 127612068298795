import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"

export default ({data}) => (
  <Layout title="Blog">
    <h2>Blog</h2>
    <div className="clear-block">
      <div id="node-49" className="node">
        <div className="content clear-block">
          {data.allMarkdownRemark.edges.map(edge => {
            const {frontmatter} = edge.node
            return (
              <div key={frontmatter.path}>{frontmatter.date} <Link to={frontmatter.path}>{frontmatter.title}</Link></div>
            )
          })}
        </div>

        <div className="clear-block">
          <div className="meta">
          </div>

        </div>

      </div>
    </div>
  </Layout>
)

export const query = graphql`query {
 allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "//blog//"}}
    sort: { order: DESC, fields: [frontmatter___date] }
  ) {
  edges {
    node {
      frontmatter {
        title
        path
        date
      }
    }
  }
} 
}`